<template>
  <div class="course" :class="{'page-is-loading': pageIsLoading}">
    <!-- <CourseSearch></CourseSearch> -->
    <Bread :list="bread"></Bread>
    <div class="course-info">
      <div class="course-info-title">{{courseInfo.courseTitle}}</div>
      <div class="course-info-wrap">
        <div class="course-info-left">
          <el-image class="course-info-img" :src="courseInfo.courseImagePath"
            alt="コース画像" fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="course-info-right">
          <!--
          <div class="course-info-item">
            <div class="course-info-item-label">{{courseInfo.groupDetail}}</div>
          </div>
          -->
          <div class="course-info-item-big">
            <span class="course-info-item-label">カテゴリ</span>
            <span class="course-info-item-value">{{courseInfo.courseTypeName}}</span>
          </div>
          <!-- <div class="course-info-item"  v-if="courseInfo.studyStatus && courseInfo.studyStatus!='0'">
            <div class="course-info-item-label">教材受講進捗</div>
            <div class="course-info-item-value">{{courseDataCount == null?'':courseDataCount.scscount }}／{{courseDataCount == null?'':courseDataCount.tmcount }}</div>
          </div>
          <div class="course-info-item"  v-if="courseInfo.studyStatus && courseInfo.studyStatus!='0'">
            <div class="course-info-item-label">単元テスト進捗</div>
            <div class="course-info-item-value">{{courseDataCount == null?'':courseDataCount.stcount }}／{{courseDataCount == null?'':courseDataCount.utcount }}</div>
          </div> -->
          <!-- <div class="course-info-item">
            <div class="course-info-item-label">講座タグ</div>
            <div class="course-info-item-value">{{courseInfo.tags.join(',')}}</div>
          </div>
          <div class="course-info-item">
            <div class="course-info-item-label">講座ステータス</div>
            <div class="course-info-item-value">{{courseInfo.status}}</div>
          </div> -->
<!--          <div class="course-info-item">-->
<!--            <div class="course-info-item-label">承認要否</div>-->
<!--            <div class="course-info-item-value">{{courseInfo.courseRegFlagName}}</div>-->
<!--          </div>-->
          <div class="course-info-desc" v-html="courseInfo.courseOutline"></div>
          <div class="course-info-item-big">
            <span class="course-info-item-label">受講期間</span>
            <span class="course-info-item-value">{{formatDate(courseInfo.openStartTime)}} ~ {{formatDate(courseInfo.openEndTime)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="course-progress"  v-if="courseDataCount!==null && courseInfo.studyStatus">
      <div class="course-progress-text">
        <span class="course-progress-title">教材受講進捗（必須）</span>
        <div class="phone-br"></div>
        <span class="course-progress-detail">{{courseDataCount.tmcount > 0 ? Math.round(100 * courseDataCount.scscount / courseDataCount.tmcount)+ '%':'100%'}}</span>               
        <span class="course-progress-detail">({{courseDataCount.scscount}}件/{{courseDataCount.tmcount}}件中)</span>
      </div>
      <div class="course-progress-bar">
        <Progress :value="courseDataCount.scscount / courseDataCount.tmcount" />
      </div>
    </div>
    <div class="course-progress" v-if="courseDataCount!==null && courseInfo.studyStatus">
      <div class="course-progress-text">
        <span class="course-progress-title">単元テスト進捗（必須）</span>
        <div class="phone-br"></div>
        <span class="course-progress-detail">{{courseDataCount.utcount > 0 ? Math.round(100 * courseDataCount.stcount / courseDataCount.utcount)+ '%':'100%'}}</span>               
        <span class="course-progress-detail">({{courseDataCount.stcount}}件/{{courseDataCount.utcount}}件中)</span>
      </div>
      <div class="course-progress-bar">
        <Progress :value="courseDataCount.stcount / courseDataCount.utcount" />
      </div>
    </div>
    <!-- <div class="course-apply-wrap" v-if="!isLogin">
      <div class="course-apply">ログインして受講申請ください。</div>
    </div> -->
    <div class="course-apply-wrap" v-if="!courseInfo.studyStatus">
      <div class="course-reason-wrap">
         <!--
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="reason"
          style="margin-bottom:10px"
        >
        </el-input>
        -->
        <div class="course-apply" @click="apply">受講申請</div>
      </div>
    </div>
    <div class="course-apply-wrap" v-if="courseInfo.studyStatus==='0'">
      <div class="course-reason-wrap">
        <div class="course-apply">受講申請中（承認待ち）</div>
        <!--
        <div class="course-apply-reason-title">申請理由</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="courseInfo.courseRegReason"
          disabled
        >
        </el-input>
        -->
      </div>
    </div>
    <div class="course-apply-wrap" v-if="['1','2','3'].indexOf(courseInfo.studyStatus)>-1">

      <div class="course-reason-wrap">
        <div class="course-apply" v-if="courseInfo.studyStatus==='1'">
          受講申請承認済み（下の教科一覧から受講してください）
        </div>
        <!-- 2022/12/21 教材受講進捗が完了しない状態　受講中とみなす　BY　甄-->
        <div class="course-apply" v-if="(courseInfo.studyStatus==='2' && ((courseDataCount.utcount!==0) || (courseDataCount.utcount===0 && courseDataCount.scscount!==courseDataCount.tmcount))) || (courseInfo.studyStatus==='3' && courseDataCount.scscount!=courseDataCount.tmcount)">
          受講中 (下の教科一覧から受講してください)
        </div>
              <!-- 2022/12/21 教材受講進捗が全部完了且つ　単元テスト進捗は全部完了の場合のみ　修了証を発行する　BY　甄-->
        <div class="course-apply" v-if="((courseInfo.studyStatus==='3' && courseDataCount.scscount===courseDataCount.tmcount) || (courseInfo.studyStatus==='2' && courseDataCount.utcount===0 && courseDataCount.scscount===courseDataCount.tmcount)) && courseInfo.courseCertificateTemplateId !== null" @click="downloadCertificate()">
          受講完了（修了証発行）
        </div>
        <div style="text-align: center" v-if="((courseInfo.studyStatus==='3' && courseDataCount.scscount===courseDataCount.tmcount) || (courseInfo.studyStatus==='2' && courseDataCount.utcount===0 && courseDataCount.scscount===courseDataCount.tmcount)) && courseInfo.courseCertificateTemplateId !== null">
          受講期間が過ぎると修了証は発行できなくなります。受講期間中にご自身で発行してお手元に保管してください。
        </div>
        <div class="course-apply" v-if="(courseInfo.studyStatus==='3' || (courseInfo.studyStatus==='2' && courseDataCount.utcount===0)) && courseInfo.courseCertificateTemplateId === null">
          受講終了
        </div>

        <!--
        <div class="course-apply" v-if="courseInfo.courseCertificateTemplateId !== null" @click="downloadCertificate()">
          受講完了（修了証発行）テスト用
        </div>
        -->
        <!--
        <div class="course-apply-reason-title">申請理由</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="courseInfo.courseRegReason"
          disabled
        >
        </el-input>
        -->
      </div>
    </div>
    <div class="course-apply-wrap" v-if="courseInfo.studyStatus==='4'">
      <div class="course-reason-wrap">
        <!--
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="reason"
        >
        </el-input>
        -->
        <div class="course-apply" @click="apply">受講申請</div>
        <!--
        <div class="course-apply-reason-title">申請理由</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="courseInfo.courseRegReason"
          disabled
          style="margin-bottom:10px"
        >
        </el-input>

        <div class="course-apply-reason-title">受講申請拒否理由</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="courseInfo.regRejectReason"
          disabled
        >
        </el-input>
        -->
      </div>
    </div>
    <div class="course-detail">
      <div class="course-detail-item">
        <div class="course-detail-title">講座詳細</div>
        <div style="white-space:pre-wrap; word-wrap:break-word;line-height:1.8em;font-size:18px" v-html="courseInfo.courseDetail"></div>
      </div>
      <!-- <div class="course-detail-item"
        v-for="(item, index) in courseInfo.detail"
        :key="index"
      >
        <div class="course-detail-title">{{item.subjectTitle}}</div>
        <div class="course-detail-content">
          <div class="course-detail-content-item-wrap" v-for="(i, idx) in item.value" :key="idx">
            <div class="course-detail-content-item" v-if="typeof i === 'string'">
              · {{i}}
            </div>
            <div v-else class="course-detail-content-item">
              <div class="course-detail-content-item-title">
                {{`${idx+1}. ${i.name}`}}
              </div>
              <div class="course-detail-content-item-value">
                {{i.value}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="course-detail-item" v-if="showList">
        <div class="course-detail-title">教科一覧
          <div style="float:right">
            <el-button native-type="button" type="warning" @click="markNotice()">！マークの説明</el-button>
          </div>
        </div>
        <div class="course-detail-content">
          <div class="app-list-sorter-wrap clearfix">
<!--            <el-alert-->
<!--              :title="'受講可能な講座は'+this.listTotal+'件です。'"-->
<!--              type="success"-->
<!--              :closable="false"-->
<!--            >-->
            <!-- </el-alert> -->
            <!-- <div class="app-list-sorter-page" style="text-align: right;margin-top:10px;">
              <span style="font-weight:bold;">

              </span>
            </div> -->
            <!-- <div class="app-list-sorter-page">
              <span style="font-weight:bold">
                {{courseInfo.userSubjectResponseResourceList.count}}
              </span>{{pageText}}
            </div>
            <div class="app-list-sorter-right">
              <span>並び替え</span>
              <el-select v-model="sortBy" size="medium" style="width: 110px">
                <el-option
                  v-for="item in sortByOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div v-loading="tableIsLoading">
            <div class="app-list-wrap">
              <div class="app-list"
                v-for="(item, index) in
                  courseInfo.subjectResponseResourceList.studentSubjectResponseResourceList"
                :key="index"
              >
                <div class="app-list-left">
                  <el-image class="app-list-left-img" :src="item.subjectImagePath"
                    alt="コース画像" fit="contain"
                    @click="item.studyStatus === '1' && goSubject(item.subjectId)"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>

                <div class="app-list-right" v-if="item.mustFlag === '0' || item.studyStatus !== '0'">
                  <div>
                    <div class="app-list-right-title">
                      <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                        <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                      <strong @click="item.studyStatus === '1' && goSubject(item.subjectId)">{{item.subjectTitle}}</strong>
                      <strong style="color:red">{{item.studyStatus === '0'?'（前の教科を受講した後で、この教科を受講してください。）':''}}</strong>                 
                    </div>
                    <div class="app-list-right-desc"> <strong style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{item.subjectOutline}}</strong></div>
                    <!-- <div class="app-list-right-desc"><strong style="white-space:pre-wrap; word-wrap:break-word;line-height:3.5em;color:red">単元受講進捗: {{item.unitStudyNum}}件/{{item.unitNum}}件中、受講完了</strong></div> -->

                    <div class="app-list-right-progress" v-if="item.unitNum && item.mustFlag === '1'">
                      <div class="app-list-right-progress-text" >
                        <span class="app-list-right-progress-title"><span>教科受講進捗（必須）</span></span>
                        <span class="course-progress-detail" >{{item.unitNum> 0 ? Math.round(100 * item.unitStudyNum / item.unitNum)+ '%':'100%'}}</span>               
                        <span class="app-list-right-progress-detail">({{item.unitStudyNum}}件/{{item.unitNum}}件中)</span>
                      </div>
                      <div class="app-list-right-progress-bar">
                        <Progress :value="item.unitStudyNum / item.unitNum" />
                      </div>
                    </div>
                  </div>
                </div>

                <!--必須な条件が満たさないため非活性 -->
                <div class="app-list-right" style="background-color:gray;" v-if="!(item.mustFlag === '0' || item.studyStatus !== '0')">
                  <div>
                    <div class="app-list-right-title-no-hover">
                      <span :class="{'app-must-flag-red': item.mustFlag === '1','app-must-flag-blue': item.mustFlag === '0'}">
                        <span>{{item.mustFlag === '1' ? '必須': '任意'}}</span></span>
                      <strong @click="item.studyStatus === '1' && goSubject(item.subjectId)">{{item.subjectTitle}}</strong>               
                    </div>
                    <div class="app-list-right-desc"> <strong style="font-size:17px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{item.subjectOutline}}</strong></div>
                    <!-- <div class="app-list-right-desc"><strong style="white-space:pre-wrap; word-wrap:break-word;line-height:3.5em;color:red">単元受講進捗: {{item.unitStudyNum}}件/{{item.unitNum}}件中、受講完了</strong></div> -->

                    <div class="app-list-right-progress" v-if="item.unitNum && item.mustFlag === '1'">
                      <div class="app-list-right-progress-text" >
                        <span class="app-list-right-progress-title"><span>教科受講進捗（必須）</span></span>
                        <span class="course-progress-detail">{{item.unitNum> 0 ? Math.round(100 * item.unitStudyNum / item.unitNum)+ '%':'100%'}}</span>               
                        <span class="app-list-right-progress-detail">({{item.unitStudyNum}}件/{{item.unitNum}}件中)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <el-pagination
            background
            :page-size="listSize"
            :pager-count="7"
            layout="prev, pager, next"
            :total="listTotal"
            @current-change="pageChange"
          >
          </el-pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import downloadRequest from '@/utils/downloadRequest';
import Bread from '@/components/Bread.vue';
import Progress from '@/components/Progress.vue';
// import CourseSearch from '@/components/CourseSearch.vue';
import tools from '@/utils/tools';
import loadingMixin from '@/utils/loadingMixin';
import { Notification } from 'element-ui';


const { shouldShowDetailList } = tools;

export default {
  name: 'Course',
  components: {
    Bread,
    Progress,
    // CourseSearch,
  },
  inject: ['isLogin'],
  mixins: [loadingMixin],
  data() {
    return {
      bread: [],
      tableIsLoading: false,
      courseId: '',
      reason: '',
      courseInfo: {},
      courseDataCount: {},
      // courseInfo: {
      //   title: '講座名',
      //   pic: 'https://cdn.catapoke.com/catalog/83852/thumb_20201218135228.jpg',
      //   category: '文化',
      //   tags: ['タグ１', 'タグ2'],
      //   status: 1,
      //   needApprove: false,
      //   abstract: '成果を確実に実現するプロジェクトマネジメント（全5回）の第1回目のコースとなります。本コースでは、プロジェクトマネジメントに関する基本知...',
      //   detail: [
      //     {
      //       name: '対象者',
      //       value: [
      //         '論理的思考の必要性を感じている方',
      //         'XXXボランティア',
      //       ],
      //     },
      //     {
      //       name: '学習目標',
      //       value: [
      //         '論理的思考の重要性、構成されている要素を理解できます',
      //         '「OJT（on-the-job training）」とは何かが分かる',
      //         'OJT研修の手順を理解できる',
      //       ],
      //     },
      //     {
      //       name: 'カリキュラム',
      //       value: [
      //         {
      //           name: 'レクチャー受講の効果',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: 'OJTとは何か？',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: 'OJTのメリット・デメリット',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: '人材育成の3段階',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: '4段階職業指導法',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: 'まとめ',
      //           value: '動画数 1本 2分',
      //         },
      //         {
      //           name: '確認テスト',
      //           value: '問題数 50',
      //         },
      //       ],
      //     },
      //     {
      //       name: '終了証取得条件',
      //       value: [
      //         'すべての教材は受講済み',
      //         'すべての教材は受講済み',
      //       ],
      //     },
      //   ],
      // },
      listTotal: 0,
      listPage: 1,
      listSize: 20,
      sortBy: '0',
      sortByOptions: [
        {
          key: '0',
          value: '新着順',
        },
        {
          key: '1',
          value: '人気順',
        },
        {
          key: '2',
          value: '評価順',
        },
      ],
      subjectList: [],
      showList: false,
    };
  },
  // watch: {
  //   courseInfo() {
  //     this.showList = shouldShowDetailList(this.courseInfo.status);
  //   },
  // },
  computed: {
    pageText() {
      const pageStart = (this.listSize * this.listPage) + 1;
      const pageEnd = this.listSize * (this.listPage + 1);
      return ` 件中 ${pageStart}～${pageEnd} 件を表示`;
    },
  },
  created() {
    this.courseId = this.$route.params.id;
    request('getCourseDetail', {
      courseId: this.$route.params.id,
    }).then((res) => {
      this.loadingClose();
      this.courseInfo = res.data;
      this.courseDataCount = res.courseDataCount;
      this.bread = [
        {
          path: '/',
          name: 'トップページ',
        },
        {
          name:  this.courseInfo.courseTitle,
        },
      ];
      // 获取到 courseInfo 后立马更新 showlist
      this.showList = shouldShowDetailList(this.courseInfo.studyStatus);
      if (this.showList) {
        // this.getSubjectList();
        this.listTotal = res.data.subjectResponseResourceList.count;
        this.subjectList = res.data.subjectResponseResourceList.studentSubjectResponseResourceList;
      }
    });
  },
  methods: {
    formatDate(d) {
      if (typeof(d) === 'string') {
        return d.replaceAll('-', '/')
      }
      return d;
    },
    // getSubjectList(data) {
    //   this.tableIsLoading = true;
    //   request('getSubjectList', data).then((res) => {
    //     this.subjectList = res;
    //   }).finally(() => {
    //     this.tableIsLoading = false;
    //   });
    // },
    // pageChange() {
    //   this.getSubjectList();
    // },
    goSubject(id) {
      this.$router.push({
        path: `/subject/${id}/${this.courseId}`,
      }).catch(() => {});
    },
    apply() {
      /*
      修正：申請理由はいらない
      if (!this.reason) {
        Notification({
          title: 'エラー',
          message: '申請理由は必須です。',
          // duration: 0,
          offset: 75,
        });
        return;
      }
      */
      request('applyCourse', {
        courseId: this.$route.params.id,
         courseRegReason: "NOT REQUIRED",
        // courseRegReason: this.reason,
      }).then((res) => {
        this.courseInfo = res.studentCourseResponseResourceList;
        this.courseDataCount = res.courseDataCount;
        // 获取到 courseInfo 后立马更新 showlist
        this.showList = shouldShowDetailList(this.courseInfo.studyStatus);
        if (this.showList) {
          // this.getSubjectList();
          this.listTotal = this.courseInfo.subjectResponseResourceList.count;
          this.subjectList = this.courseInfo.subjectResponseResourceList
            .studentSubjectResponseResourceList;
        }
      });
    },
    downloadCertificate() {
      downloadRequest('downloadCertificate', '修了証.pdf', {courseId: this.courseId}).catch(error => {console.log(error);});
    },
    goBack() {
      history.go(-1);
    },
    markNotice(){
      Notification.closeAll()
      Notification({
        title: 'マーク説明',
        dangerouslyUseHTMLString: true,
        message: '<span class="app-must-flag-red"><span>必須</span></span>かならず受講完了する必要がある教科<p><span class="app-must-flag-blue"><span>任意</span></span>任意で受講すればよい教科',
        offset: 75,
      });
    }
  },
  mounted() {
  },
};
</script>

<style lang="less">
.course {
  margin: 30px 0;

  .course-info {
    margin-bottom: 20px;

    .course-info-title {
      text-align: center;
      font-size: 26px;
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .course-info-wrap {
      display: flex;

      .course-info-left {
        margin-right: 10px;
        box-sizing: border-box;
        // border: 1px solid #ccc;

        .course-info-img {
          width: 320px;
          min-height: 230px;
          // height: 282px;
          .image-slot {
            min-height: 230px;
          }
        }
      }
      .course-info-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        // border: 1px solid #ccc;
        box-sizing: border-box;

        .course-info-item {
          font-size: 15px;
          // border-bottom: 1px solid #ccc;

          .course-info-item-label {
            padding: 8px;
            width: 120px;
            display: inline-block;
            // background-color: #eee;
          }
          .course-info-item-value {
            padding: 8px;
            display: inline-block;
          }
        }
        .course-info-item-big {
          .course-info-item-label {
            background-color: #0f3364;
            color: #fff;
            padding: 7px 18px;
            border-radius: 5px;
            margin-right: 10px;
            font-size: 18px;
            display: inline-block;
          }
          .course-info-item-value {
            padding: 8px;
            display: inline-block;
            font-size: 18px;
          }
        }
        .course-info-desc {
          flex: 1;
          padding: 8px;
          font-size: 17px;
          white-space:pre-wrap;
          word-wrap:break-word;
          line-height:1.5em;
          margin-bottom: 10px;
        }
      }
    }
  }
  .course-progress {
    margin-bottom: 30px;

    .course-progress-text {
      // height: 35px;
      margin-bottom: 5px;
      .course-progress-title {
        display: inline-block;
        background-color: #0f3364;
        color: #fff;
        padding: 7px 18px;
        border-radius: 5px;
        margin-right: 15px;
        font-size: 18px;
      }
      .course-progress-detail {
        display: inline-block;
        font-size: 18px;
        margin-right: 20px;
      }
    }
    .course-progress-bar {
      padding: 0 8%;
    }
  }
  .course-apply-wrap {
    .course-reason-wrap {
      margin: 0 4%;
      margin-bottom: 40px;
    }
    .course-apply {
      border-radius: 10px;
      background-color: rgb(228, 81, 7);
      color: #fff;
      padding: 20px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .course-apply-reason-title {
      margin-bottom: 10px;
    }
  }
  .course-detail {
    .course-detail-item {
      margin-bottom: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      padding-top: 30px;

      .course-detail-title {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 20px;
        margin-left: 10px;
      }
      .course-detail-content {
        font-size: 18px;
        padding: 10px 0;

        .course-detail-content-item-wrap {
          .course-detail-content-item {
            margin-bottom: 18px;

            .course-detail-content-item-title {
              margin-bottom: 8px;
            }
            .course-detail-content-item-value {
              font-size: 18px;
              color: #777;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .course {
    .course-info-wrap {
      flex-direction: column;
      .course-info-left {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
